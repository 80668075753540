import React from 'react';
import "../four-oh-four.css";
import { Link } from "gatsby";

import PageMetaDefault from "../components/page-meta-default";
import Header from "../components/header";

class FourOhFour extends React.PureComponent {
  render() {
    return (
      <>
        <PageMetaDefault pageTitle="404 Doesn't exist" />
        <Header />
        <div id="contentWrapper">
          <div id="fourohfour-wrapper">
            <div className="error">404</div>
            <br/><br/>
            <span className="info">Page not found</span>
            <br />
            <div id="not-found-text">
              The page you are looking for does not exist.<br />
              Please <Link to="/" id="not-found-link">click here</Link> to visit the homepage
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default FourOhFour;
